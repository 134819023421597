const RunMode = 'DEV1';
// export default {
//   title: "TMS",
//   ReportHost: "http://172.16.17.196:5000/",
//   WebAPIHost: RunMode==='DEV'? "http://localhost:3000" : "https://api2.wa.techluxid.com",
//   PrivateKey: 'HastalaVista2020Ascend',
//   WSHost: 'https://api.wa.techluxid.com',
//   // WAHost: 'https://whatsapp-api.techluxid.com',
//   WAESocketHost: 'https://api.wa.techluxid.com',
//   WAHost: 'https://api.wa.techluxid.com',
//   // WAESocketHost: 'http://localhost:6001',
// };

export default {
  title: "TMS",
  ReportHost: "http://172.16.17.196:5000/",
  WebAPIHost: RunMode==='DEV'? "http://localhost:6002" : "https://api.wa.techluxid.com",
  PrivateKey: 'HastalaVista2020Ascend',
  WSHost: 'https://engine.wa.techluxid.com',
  WAHost: 'https://engine.wa.techluxid.com',
  WAESocketHost: 'https://engine.wa.techluxid.com',
  // WAHost: 'http://localhost:6001',
  // WAESocketHost: 'http://localhost:6001'
};