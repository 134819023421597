import auth from "@/auth";
import { createRouter, createWebHistory } from "vue-router";

import Home from "@/views/home-page";

import defaultLayout from "@/layouts/side-nav-inner-toolbar";
import simpleLayout from "@/layouts/single-card";
// import webDocumentLayout from "@/layouts/web-document.vue";
import WARouter from './wa.router';


function loadView(view) {
  return () => import (`@/views/${view}.vue`)
}

const router = new createRouter({
  routes: [
    {
      path: "/home",
      name: "home",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Home
    },
    ...WARouter,
    {
      path: `/Media`,
      name: 'Media',
      meta: {
          Title: 'Media',
          requiresAuth: true,
          layout: defaultLayout
      },
      component: () => import (`@/views/media-page.vue`)
  },
    {
        path: `/License`,
        name: 'License',
        meta: {
            Title: 'License',
            requiresAuth: true,
            layout: defaultLayout
        },
        component: () => import (`@/views/whatsapp/license-page.vue`)
    },
    {
      path: "/login-form",
      name: "login-form",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Enterprise Application Suite"
      },
      component: loadView("login-form")
    },
    {
      path: "/reset-password",
      name: "reset-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Reset Password",
        description: "Please enter the email address that you used to register, and we will send you a link to reset your password via Email."
      },
      component: loadView("reset-password-form")
    },
    {
      path: "/create-account",
      name: "create-account",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Sign Up"
      },
      component: loadView("create-account-form"),
    },
    {
      path: "/change-password/:recoveryCode",
      name: "change-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Change Password"
      },
      component: loadView("change-password-form")
    },
    {
      path: "/",
      redirect: "/home"
    },
    {
      path: "/recovery",
      redirect: "/home"
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: "/home"
    }
  ],
  history: createWebHistory()
});


router.beforeEach((to, from, next) => {
  if (to.name === "login-form" && auth.loggedIn()) {
    next({ name: "home" });
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!auth.loggedIn()) {
      next({
        name: "login-form",
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
