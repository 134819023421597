import Config from './app-info'
const defaultUser = {
  email: localStorage.getItem("ASCEND-FULLNAME"),
  userName: localStorage.getItem("ASCEND-USERNAME"),
  avatarUrl: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/employees/06.png'
};

export default {
  _user: defaultUser,
  loggedIn() {
    return !!localStorage.getItem("ASCEND-TOKEN");
  },

  async logIn(UserName, Password) {
    try {
      const response = await fetch(`${Config.WebAPIHost}/WA/User/Login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          Identifier: UserName,
          Password: Password
        })
      }).then((response) => response.json())
      this._user = {};
      this._user.email = response.Item.UserName;
      this._user.userName = response.Item.UserName;
      localStorage.setItem("ASCEND-FULLNAME", response.Item.UserName);
      localStorage.setItem("ASCEND-USERNAME", response.Item.Username);
      return {
        isOk: true,
        data: response
      };
    }
    catch {
      return {
        isOk: false,
        message: "Authentication failed"
      };
    }
  },
  async logOut() {
    this._user = null;
    localStorage.clear();
  },

  async getUser() {
    try {
      // Send request

      return {
        isOk: true,
        data: this._user
      };
    }
    catch {
      return {
        isOk: false
      };
    }
  },

  async resetPassword(email) {
    try {
      // Send request
      console.log(email);

      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to reset password"
      };
    }
  },

  async changePassword(email, recoveryCode) {
    try {
      // Send request
      console.log(email, recoveryCode);

      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to change password"
      }
    }
  },

  async createAccount(email, password) {
    try {
      // Send request
      console.log(email, password);

      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to create account"
      };
    }
  }
};
