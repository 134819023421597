import 'devextreme/dist/css/dx.common.css';
import '@/themes/generated/theme.base.css';
// import './themes/generated/theme.dark.base.css';
import '@/themes/generated/theme.additional.css';

import "@devexpress/analytics-core/dist/css/dx-analytics.common.css";
import "@devexpress/analytics-core/dist/css/dx-analytics.light.css";
import "devexpress-reporting/dist/css/dx-webdocumentviewer.css";
// import 'devextreme/integration/jquery';
import { createApp }  from "vue";
import router from "@/routes/router";
import io from 'socket.io-client';
import App from "./App";
import appInfo from "./app-info";
import VueCryptojs from 'vue-cryptojs';
import store from './store';
const app = createApp(App).use(store);
app.use(router);
app.use(VueCryptojs);
app.config.globalProperties.$appInfo = appInfo;
app.config.globalProperties.$setSocket = (user) => {
    const socket = io(appInfo.WSHost, {
        autoConnect: true,
        transports: ["polling"],
        query: {
            u: app.CryptoJS.AES.encrypt(user, appInfo.PrivateKey).toString(),
            t: app.CryptoJS.AES.encrypt('Ascend2022', appInfo.PrivateKey).toString(),
            r: app.CryptoJS.AES.encrypt('Ascend', appInfo.PrivateKey).toString(),
            o: app.CryptoJS.AES.encrypt('Waruna', appInfo.PrivateKey).toString()
        }
    });
    app.config.globalProperties.$socket = socket;
    return app.config.globalProperties.$socket;
};
app.config.globalProperties.$waSetSocket = (user) => {
    const socket = io(appInfo.WAESocketHost, {
        autoConnect: true,
        transports: ["polling"],
        query: {
            u: app.CryptoJS.AES.encrypt(user, appInfo.PrivateKey).toString(),
            t: app.CryptoJS.AES.encrypt('Ascend2022', appInfo.PrivateKey).toString(),
            r: app.CryptoJS.AES.encrypt('Ascend', appInfo.PrivateKey).toString(),
            o: app.CryptoJS.AES.encrypt('Waruna', appInfo.PrivateKey).toString()
        }
    });
    app.config.globalProperties.$socket = socket;
    return app.config.globalProperties.$socket;
};
app.config.globalProperties.$setWASocket = (user) => {
    const socket = io(appInfo.WAHost, {
        autoConnect: true,
        transports: ["polling"],
        query: {
            u: app.CryptoJS.AES.encrypt(user, appInfo.PrivateKey).toString(),
            t: app.CryptoJS.AES.encrypt('Ascend2022', appInfo.PrivateKey).toString(),
            r: app.CryptoJS.AES.encrypt('Ascend', appInfo.PrivateKey).toString(),
            o: app.CryptoJS.AES.encrypt('Waruna', appInfo.PrivateKey).toString()
        }
    });
    app.config.globalProperties.$WASocket = socket;
    return app.config.globalProperties.$WASocket;
};
app.config.globalProperties.$error = (err) => {
    if(err.message == 'License.NotValid' || err.message == 'License.Required' || err.message == 'Customer.NotRegistered'){
        if(router.currentRoute._value.fullPath !== '/WA/License' && router.currentRoute._value.fullPath !== '/WA/Customer'){
            router.push({name: 'WA.License'});
        }
    }
};
app.mount('#app');
