import { createStore } from 'vuex'
import createMultiTabState from 'vuex-multi-tab-state';
export default createStore({
  state: {
    count: 0
  },
  getters: {
  },
  mutations: {
    increment(state) {
      state.count += 1;
    },
  },
  actions: {
  },
  modules: {
  },
  plugins: [
    createMultiTabState({
      statesPaths: ['count'],
    }),
  ]
})
