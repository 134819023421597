import defaultLayout from "@/layouts/side-nav-inner-toolbar";
import balnkPage from "@/layouts/blank-page";
const root = '/WA';
export default [
    {
        path: root,
        name: "WA",
        meta: {
            requiresAuth: true,
            layout: defaultLayout
        },
        children: [
            {
                path: `${root}/Device`,
                name: 'WA.Device',
                meta: {
                    Title: 'Device'
                },
                component: () => import (`@/views/whatsapp/device-page.vue`)
            },
            {
                path: `${root}/Scan`,
                name: 'WA.Scan',
                meta: {
                    Title: 'Scan Device'
                },
                component: () => import (`@/views/whatsapp/scan-page.vue`)
            },
            {
                path: `${root}/Outbox`,
                name: 'WA.OutBox',
                meta: {
                    Title: 'Outbox'
                },
                component: () => import (`@/views/whatsapp/outbox-page.vue`)
            },
            {
                path: `${root}/Customer`,
                name: 'WA.Customer',
                meta: {
                    Title: 'Customer'
                },
                component: () => import (`@/views/whatsapp/customer-page.vue`)
            },
            {
                path: `${root}/User`,
                name: 'WA.User',
                meta: {
                    Title: 'User'
                },
                component: () => import (`@/views/whatsapp/user-page.vue`)
            },
            {
                path: `${root}/Balance`,
                name: 'WA.Balance',
                meta: {
                    Title: 'Balance'
                },
                component: () => import (`@/views/whatsapp/balance-page.vue`)
            },
            {
                path: `${root}/Token`,
                name: 'WA.Token',
                meta: {
                    Title: 'Token'
                },
                component: () => import (`@/views/whatsapp/token-page.vue`)
            },
            {
                path: `${root}/GrabContact`,
                name: 'WA.Grab.Contact',
                meta: {
                    Title: 'Grab Contact'
                },
                component: () => import (`@/views/whatsapp/grab-contact-page.vue`)
            },
            {
                path: `${root}/Blast`,
                name: 'WA.Blast',
                meta: {
                    Title: 'Blast'
                },
                component: () => import (`@/views/whatsapp/blast-page.vue`)
            },
            {
                path: `${root}/Bot`,
                name: 'WA.Bot',
                meta: {
                    Title: 'Bot'
                },
                component: () => import (`@/views/whatsapp/bot-page.vue`)
            },
            {
                path: `${root}/Bot`,
                name: 'WA.Contact',
                meta: {
                    Title: 'Contact'
                },
                component: () => import (`@/views/whatsapp/bot-page.vue`)
            },
            {
                path: `${root}/Role`,
                name: 'WA.Role',
                meta: {
                    Title: 'Role'
                },
                component: () => import (`@/views/whatsapp/role-page.vue`)
            },
            {
                path: `${root}/UserRole`,
                name: 'WA.UserRole',
                meta: {
                    Title: 'User Role'
                },
                component: () => import (`@/views/whatsapp/user-role-page.vue`)
            },
            {
                path: `${root}/Contact`,
                name: 'WA.Contact.List',
                meta: {
                    Title: 'Contact'
                },
                component: () => import (`@/views/whatsapp/contact-page.vue`)
            },
            {
                path: `${root}/ContactGroup`,
                name: 'WA.ContactGroup.List',
                meta: {
                    Title: 'Contact Group'
                },
                component: () => import (`@/views/whatsapp/contact-group-page.vue`)
            },
            {
                path: `${root}/Group`,
                name: 'WA.User.Group',
                meta: {
                    Title: 'Group'
                },
                component: () => import (`@/views/whatsapp/group-page.vue`)
            },
            {
                path: `${root}/Menu`,
                name: 'WA.Menu',
                meta: {
                    Title: 'Menu'
                },
                component: () => import (`@/views/whatsapp/menu-page.vue`)
            },
            {
                path: `${root}/Setting/DBConfig`,
                name: 'WA.Setting.DBConfig',
                meta: {
                    Title: 'Database Config'
                },
                component: () => import (`@/views/whatsapp/db-config-page.vue`)
            },
            {
                path: `${root}/Setting/DBConnection`,
                name: 'WA.Setting.DBConnection',
                meta: {
                    Title: 'Database Connection'
                },
                component: () => import (`@/views/whatsapp/db-connection-page.vue`)
            },
            {
                path: `${root}/Setting/DBConfig/Detail`,
                name: 'WA.Setting.DBConfig.Detail',
                meta: {
                    Title: 'Database Config Detail'
                },
                component: () => import (`@/views/whatsapp/db-config-detail-page.vue`)
            },
           
        ],
      },
      {
        path: `${root}/License`,
        name: 'WA.License',
        meta: {
            Title: 'License',
            requiresAuth: false,
            layout: balnkPage
        },
        component: () => import (`@/views/whatsapp/license-register-page.vue`)
    },
]